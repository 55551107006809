import Header from '../Header/Header';
import logo from '../Header/AP_Logo.png'
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import './Login.css'
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import 'firebase/auth';
import { getAuth,signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";
import {app} from '../../firebase';
import UserContext from "../../UserContext";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  // const navigate = useNavigate();

  const { signIn, authenticated,setError,isAdmin,loading } =
  React.useContext(UserContext);

  const auth = getAuth(app);


const defaultTheme = createTheme();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      const email = data.get('email');
      const password = data.get('password')
      signIn({ email, password });
    } catch (err) {
      console.log(err)
    }

  };


  return (
    <Grid container justifyContent={'center'}>
      <ToastContainer/>
        <Grid xs={12} sm={12} md={12} lg={12} marginLeft={2} item> <Link href='/'><img className='logo' width="180px" src={logo} alt="logo"/></Link></Grid>
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
    
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            {!loading ? (<div>
              <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
              </div>) : (<div className='d-flex justify-content-center align-items-center mt-5 ms-5'><div className="loading-circle"></div></div>)}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>

    </Grid>
  )
}

export default Login





function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit">
        A.P Enterprise
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}






// import React from 'react';
// import { Avatar, Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container, Card, CardContent } from '@mui/material';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { getAuth } from "firebase/auth";
// import { app } from '../../firebase';
// import UserContext from "../../UserContext";
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Header from '../Header/Header';
// import logo from '../Header/AP_Logo.png'
// import './Login.css'; // Ensure your CSS file handles body background

// const Login = () => {
//   const { signIn } = React.useContext(UserContext);
//   const auth = getAuth(app);
//   const defaultTheme = createTheme();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);
//     const email = data.get('email');
//     const password = data.get('password');
//     signIn({ email, password });
//   };

//   return (
//         <Grid container justifyContent={'center'}>
//            <ToastContainer/>
//        <Grid className='head-logo' xs={5} sm={4} md={2} lg={2} marginLeft={2} item> <Link href='/'><img className='logo' width="180px" src={logo} alt="logo"/></Link></Grid>    
//     <ThemeProvider theme={defaultTheme}>
//       <Container component="main" maxWidth="xl" sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         minHeight: '100vh',
//         justifyContent: 'center',
//         alignItems: 'center',
//         background: 'linear-gradient(to right, #7b4397, #dc2430)' 
//       }}>
//         <CssBaseline />
//         <Card sx={{ minWidth: 275 }}>
//           <CardContent>
//             <ToastContainer />
//             <Box sx={{ my: 4, mx: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//               <Typography component="h1" variant="h5">
//                 Sign in
//               </Typography>
//               <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
//                 <TextField
//                   margin="normal"
//                   required
//                   fullWidth
//                   id="email"
//                   label="Email Address"
//                   name="email"
//                   autoComplete="email"
//                   autoFocus
//                 />
//                 <TextField
//                   margin="normal"
//                   required
//                   fullWidth
//                   name="password"
//                   label="Password"
//                   type="password"
//                   id="password"
//                   autoComplete="current-password"
//                 />
//                 <Button
//                   type="submit"
//                   fullWidth
//                   variant="contained"
//                   sx={{ mt: 3, mb: 2 }}
//                 >
//                   Sign In
//                 </Button>
//                 <Grid container>
//                   <Grid item xs>
//                     <Link href="#" variant="body2">
//                       Forgot password?
//                     </Link>
//                   </Grid>
//                 </Grid>
//               </Box>
//             </Box>
//           </CardContent>
//         </Card>
//         <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 8, mb: 4, color:"white"}}>
//           {'Copyright © '}
//           <Link color="inherit">
//             A.P Enterprise
//           </Link>
//           {' '}
//           {new Date().getFullYear()}
//           {'.'}
//         </Typography>
//       </Container>
//     </ThemeProvider>
//     </Grid>
//   );
// }

// export default Login;

