import React, { useEffect, useState } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Pagination } from "react-bootstrap";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PaymentHistory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [transactions, setTransaction] = useState();
  const [isFilter, setFilter] = useState(false);
  const [startDate,setStartDate] = useState();
  const [endDate,setEndDate] = useState();
  const [transactionType,setTransactionType] = useState('ALL');

  const toggleFilter = () => {
    setFilter((prevState) => !prevState);
  };


  const getTransactions = async () => {
    try {
      const resp = await axios.get(
        `https://apenterprise-client.onrender.com/transaction/get-customer-transaction/${id}`
      );
      setTransaction(resp.data);
    } catch (err) {
      console.log(err);
    }
  };


  const filteredTransactions = async () => {
    if(startDate && endDate && transactionType){
        try {
            const params = {
                startDate,
                endDate,
                transactionType,
                employee_id: id
            };
    
            const response = await axios.get('https://apenterprise-client.onrender.com/transaction/filter-transaction', { params });
    
            setTransaction(response.data);
            // toggleFilter();
        } catch (error) {
            console.error('Error fetching filtered transactions:', error);
            throw error;
        }
    } else {
        alert("Please enter the filter details")
    }
    
};

//   const navigateToPayroll = () => {
//     navigate("/payroll");
//   };

  useEffect(() => {
    getTransactions();
  }, []);

  const formatDate = (dateString) => {
    if (dateString) {
      const date = new Date(dateString);

      const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
      };

      return date.toLocaleDateString("en-GB", options);
    } else {
      return undefined;
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 15;

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentTransactions = transactions?.slice(
    indexOfFirstUser,
    indexOfLastUser
  );

  const totalPages = Math.ceil(transactions?.length / usersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (
    let i = Math.max(1, currentPage - 1);
    i <= Math.min(totalPages, currentPage + 1);
    i++
  ) {
    pageNumbers.push(i);
  }


  const resetButton = () => {
    getTransactions();
    toggleFilter();
  }


  return (
    <div>
      <DashboardHeader />
      <div className="container d-flex flex-column justify-content-center mt-4">
        <div className={isFilter? "d-flex justify-content-center align-items-center": "d-flex justify-content-end"} >
          {isFilter ?(<>
            <div class="input-group mb-3 w-25">
              <span class="input-group-text">From</span>
              <input
              onChange={(e) => setStartDate(e.target.value)}
                type="date"
                class="form-control border border-primary w-25 "
                placeholder="Start Date"
              />
            </div>

            <div class="input-group mb-3 w-25 ms-3">
              <span class="input-group-text">To</span>
              <input
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                class="form-control border border-primary w-25 "
                placeholder="End Date"
              />
            </div>

            <div class="input-group mb-3 w-25 ms-3">
              <span class="input-group-text">Type</span>
              <select onChange={(e) => setTransactionType(e.target.value)}
                class="form-select"
                className="border-primary text-center pt-2 pb-2"
                id="inputGroupSelect01"
              >
                <option value="ALL">All Transactions</option>
                <option value="Bank Transfer">Debit</option>
                <option value="Cash">Credit</option>
              </select>
            </div>
          </>): (<></>)}
            <div>
                {isFilter ? <button onClick={filteredTransactions} className="btn btn-outline-info mb-3 ms-3 ">Apply</button>:
            <button onClick={toggleFilter} className="btn btn-outline-info mb-3 ms-3 "><FontAwesomeIcon className="mr-4"  icon={faFilter} style={{color: "#454342",}} />Filter</button>}
            </div>
            {isFilter ? <button onClick={resetButton} className="btn btn-warning mb-3 ms-3 ">Reset</button> : <></>}
          
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sl No</th>
              <th scope="col">Transac / Bill No</th>
              <th scope="col">Date</th>
              <th scope="col">Type of Transaction</th>
              <th scope="col">Amount (Rs)</th>
            </tr>
          </thead>
          <tbody>
            {currentTransactions?.map((transaction, index) => {
                const startingSerialNumber = (currentPage - 1) * usersPerPage + 1;
                const serialNumber = startingSerialNumber + index;

                return  (
                    <tr key={index}>
                      <td>{serialNumber}</td>
                      <td>{transaction.transaction_id}</td>
                      <td>{formatDate(transaction.date)}</td>
                      <td>{transaction.transaction_type}</td>
                      <td>{transaction.amount}.00</td>
                    </tr>
                  )
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Pagination>
          {pageNumbers.map((number) => (
            <Pagination.Item key={number} onClick={() => paginate(number)}>
              {number}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
    </div>
  );
};

export default PaymentHistory;
