import React, { useState } from 'react'
import DashboardHeader from '../Dashboard/DashboardHeader'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './customer.css'

const UploadStatement = () => {

    const [name,setName] = useState();
    const [file,setFile] = useState();
    const [loading,setLoading] = useState(false);

    const {id} = useParams();
    const navigate = useNavigate();
    
  const generatePayments = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('pdf', file);
    formData.append('user', name);
    setLoading(true);
    try {
        const body = formData
        const resp = await axios.post(`https://apenterprise-client.onrender.com/upload/${id}`,body);
      console.log(resp.data)
      setLoading(false);
      toast.success(`${resp.data.length} Payments have been added to the system!`, {
        position: "top-right",
        autoClose: 2200,
        onClose: () => navigate(`/customer-transactions/${id}`)
    });
   
    } catch (error) {
      setLoading(false);
      console.error('Error:', error);
    }
  };




  return (
    <div>
    <DashboardHeader />
    <div className="container d-flex justify-content-center align-items-center">
    <ToastContainer />
      <div class="card w-75 mt-5">
        <div class="card-body d-flex flex-column justify-content-center align-items-center">
          <h5 class="card-title text-center mb-4">Upload Bank Statement</h5>

          <div class="input-group mb-3">
  <span class="input-group-text" id="basic-addon1">Search Client Name</span>
  <input onChange={(e)=> setName(e.target.value)} type="text" class="form-control" placeholder="Enter Name" aria-label="Username" aria-describedby="basic-addon1"/>
</div>


<div class="input-group mb-3">
  <input onChange={(e)=> setFile(e.target.files[0])} type="file" class="form-control" id="inputGroupFile02"/>
  <label class="input-group-text" for="inputGroupFile02">Upload</label>
</div>



        <button onClick={generatePayments} className="btn btn-outline-success">Submit</button>
        </div>
      </div>

      {loading ? (<div className="loading-background">
  <div className="loading-circle2"></div>
</div>
):(<></>)}
    </div>
  </div>
  )
}

export default UploadStatement