import React, { useContext, useEffect, useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../Header/AP_Logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import "./Dashboard.css";
import UserContext from "../../../UserContext";

function DashboardHeader() {
  const navigate = useNavigate();
  const location = useLocation();

  const { logout, isAdmin, userDetails } = useContext(UserContext);
  const [user, setUser] = useState("");

  const redirectDashboard = () => {
    navigate("/admin-dashboard");
  };

  const redirectLandingPage = () => {
    navigate("/");
  };

  useEffect(() => {
    const username = userDetails.user.email.split("@")[0];
    setUser(username);
  }, []);

  return (
    <Navbar collapseOnSelect expand="sm" bg="secondary" variant="dark">
      <Container>
        <Navbar.Brand onClick={redirectLandingPage}>
          <img className="logo" width="130px" src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {(isAdmin || user !== "karim") &&
            !location.pathname.includes("/admin-dashboard") && (
              <Nav className="me-auto">
                <Nav.Link
                  onClick={redirectDashboard}
                  className="lead font-weight-700 nav-link"
                >
                  Home
                </Nav.Link>
              </Nav>
            )}

          <Nav className="justify-content-center">
            {/* {!isAdmin && (
                            <Nav.Link className="lead font-weight-700">
                                Logged in as Dibendu
                            </Nav.Link>
                        )} */}

            <Nav.Link className="lead font-weight-700">
              Signed in as: {isAdmin ? "Admin" : user}
            </Nav.Link>
          </Nav>
          <Nav className="justify-content-end">
            <Nav.Link onClick={logout} className="btn btn-outline-danger">
              Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default DashboardHeader;
