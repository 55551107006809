import React from 'react'

const InvoiceGrid = (props) => {

    const {user,date,invoiceNo,company} = props.invoices;
  return (
    <div className='invoice'>
    <h3 className='flex1'>{invoiceNo}</h3>
    <h3 className='flex2'>{date}</h3>
    <h3 className='flex2'>{company}</h3>
    <h3 className='flex1'>{user}</h3>
  </div>
  )
}

export default InvoiceGrid