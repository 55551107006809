import React, { useRef } from 'react'
import './Footer.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot,faEnvelopeCircleCheck,faHeart,faHandSpock } from "@fortawesome/free-solid-svg-icons";
import emailjs from '@emailjs/browser';


const Footer = () => {

    const form = useRef();


    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_0lvzs4i', 'template_8ivd0q8', form.current, 'N6Hep-Fvvc9K5SjVt')
          .then((result) => {
              console.log(result.text);
              alert("Message Sent!")
          }, (error) => {
              console.log(error.text);
          });
      };



  return (
    <div className="contacts">
    <div className="contact-div">
        <h2 style={{color:"#fff"}} className='title-contact'>Contact Us</h2>
        <div>
        <span><FontAwesomeIcon icon={faMapLocationDot} /></span><h5 className="contact-text">A.P Enterprise</h5>
        <h5 className="contact-text">P-300, Darga Road</h5>
        <h5 className="contact-text">Kolkata - 700017</h5>
        </div>
        <div>
            <span><FontAwesomeIcon icon={faEnvelopeCircleCheck} /></span>
            <h5 className="contact-text">Mobile - +91 8017915682</h5>
            <a className="mail-link contact-text" href="mailto:apenterprisep300@gmail.com"><h5 className='contact-text'>Email id - apenterprise72@yahoo.com</h5></a>
        </div>
    </div>
    <div className="message-div">
        <h3 className='leave-message'>Leave a message for us. <FontAwesomeIcon style={{color:"red"}} icon={faHeart} /> <FontAwesomeIcon style={{color:"#fae20f"}} icon={faHandSpock} /></h3>
        <div>
            <form ref={form} onSubmit={sendEmail}>
            <div>
                <input className="nameInput" name='client-name' type="text" placeholder="Full Name*" required/>
                <input className='emailInput' name='client-email' type="email" placeholder="Email*" required/>
            </div>
            <input name='subject' placeholder="Subject*" className="subject-input" type="text"/>
            <div>
            
                <h5 className="messageLabel">Your Message:</h5>
                <textarea name='message' className="textarea-input"></textarea>
            </div>
            <input className='message-submit' type="submit" value="Send" />
            </form>
         
        </div>
    </div>
</div>
  )
}

export default Footer