import React, { useState } from 'react'
import DashboardHeader from '../Dashboard/DashboardHeader'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { storage } from '../../../firebase';
import { ref,uploadBytes,getDownloadURL  } from "firebase/storage";

const CreatePayee = () => {

    const [name,setName] = useState();
    const [designation,setDesignation] = useState();
    const [salary,setSalary] = useState();
    const [file,setFile] = useState();
    const [address,setAddress] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const [joiningDate,setJoiningDate] = useState();

    const navigate = useNavigate();


    const storageRef = ref(storage);
    const imagesRef = ref(storageRef, 'profilePictures');


    const createPayee = async () => {
      if(!name || !designation || !salary || !address || !phoneNumber){
        alert("Please complete the form");
      } else {
      try {
          const fileName = `${name.split(" ")[0]}.jpg`;
          const spaceRef = ref(imagesRef, fileName);
  
          uploadBytes(spaceRef, file).then((snapshot) => {
              getDownloadURL(snapshot.ref).then((downloadURL) => {
        
                  const body = {
                      name,
                      designation,
                      salary,
                      address,
                      phoneNumber,
                      photoUrl: downloadURL.toString(), 
                  };
  
                  // Now make the axios post request
                  axios.post('https://apenterprise-client.onrender.com/payee/add-member', body).then(() => {
                      toast.success('Successfully Added a payee!', {
                          position: "top-right",
                          autoClose: 2200,
                          onClose: () => navigate('/payroll')
                      });
                  });
              });
          });
      } catch(err) {
          console.log(err);
      }
    }
  };
  





  return (
    <div>
    <DashboardHeader />
    
    <div className="container d-flex justify-content-center align-items-center">
    <ToastContainer />
      <div class="card w-75 mt-5">
        <div class="card-body d-flex flex-column justify-content-center align-items-center">
          <h5 class="card-title text-center mb-4">Enter Payee Details</h5>

          <div class="input-group mb-3">
  <span class="input-group-text" id="basic-addon1">Name</span>
  <input onChange={(e)=> setName(e.target.value)} type="text" class="form-control" placeholder="Enter Name" aria-label="Username" aria-describedby="basic-addon1"/>
</div>

<div class="input-group mb-3">
  <span class="input-group-text" id="basic-addon1">Designation</span>
  <input  onChange={(e)=> setDesignation(e.target.value)} type="text" class="form-control" placeholder="Enter Designation" aria-label="Designation" aria-describedby="basic-addon1"/>
</div>

<div class="input-group mb-3">
  <span class="input-group-text">Salary Rs.</span>
  <input  onChange={(e)=> setSalary(e.target.value)} type="number" class="form-control" aria-label="Amount (to the nearest dollar)"/>
  <span class="input-group-text">.00</span>
</div>

<div class="input-group mb-3">
  <span class="input-group-text">Address</span>
  <input onChange={(e)=> setAddress(e.target.value)} type="text" class="form-control" aria-label="Address" placeholder='Enter Address'/>
</div>

<div class="input-group mb-3">
  <span class="input-group-text">Mobile Number</span>
  <input onChange={(e)=> setPhoneNumber(e.target.value)} type="text" class="form-control" aria-label="PhoneNumber" placeholder='Enter Phone Number'/>
</div>

<div class="input-group mb-3">
  <span class="input-group-text">Joining Date</span>
  <input  type="date" class="form-control" placeholder="Date of Joining"/>
</div>


<div class="input-group mb-3">
  
  <input  onChange={(e)=> setFile(e.target.files[0])} class="form-control" type="file" id="formFile"/>
  <span class="input-group-text">Photograph</span>
</div>


{/* <div class=" mb-3" >
  <label for="formFile" class="form-label " >Photograph</label>
  <input class="form-control" type="file" id="formFile"/>
</div> */}



        <button onClick={createPayee} className="btn btn-outline-success">Submit</button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CreatePayee