import { initializeApp } from "firebase/app";
import {  getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import 'firebase/firestore'


const firebaseConfig = {
  apiKey: "AIzaSyAX62vFShKhteo6ZHon903bW1j44QZRmyY",
  authDomain: "apenterprise-bill.firebaseapp.com",
  projectId: "apenterprise-bill",
  storageBucket: "apenterprise-bill.appspot.com",
  messagingSenderId: "73975284517",
  appId: "1:73975284517:web:ff811fff0a9f0f320a90cc",
  measurementId: "G-R31V12V1SD"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
