import React, { useState } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NewEntry = () => {

  const {id} = useParams();
  const navigate = useNavigate();

  const [amount,setAmount] = useState();
  const [transaction_type,setTransaction_type] = useState('Bank Transfer');
  const [date,setDate] = useState(new Date());
  const [description,setDescription] = useState();



  const createTransaction = async () => {
    if(amount && transaction_type && date){
      const result = window.confirm("Do you want to create the transaction ?");
      if(result){
        try {
          const body = {
            employee_id:id,
            amount,
            transaction_type,
            date,
            description
          }
  
          await axios.post(`https://apenterprise-client.onrender.com/transaction/add-transaction/${id}`,body) 
  
          toast.success('Successfully Added the transaction!', {
            position: "top-right",
            autoClose: 2200,
            onClose: () => navigate('/payroll')
        });
    } catch(err) {
        toast.error('Something went wrong! Try Again!', {
            position: "top-right",
            autoClose: 2200,
        });
        console.log(err);
    }
      }
    } else {
      alert("Please enter the details before submitting")
    }
  }




  return (
    <div>
      <DashboardHeader />
      <ToastContainer />
      <div className="container d-flex justify-content-center align-items-center">
        <div class="card w-75 mt-5">
          <div class="card-body d-flex flex-column justify-content-center align-items-center">
            <h5 class="card-title text-center mb-4">Enter Transaction Details</h5>

            <div class="input-group mb-3 w-75 ">
  <span class="input-group-text border-primary">Rs</span>
  <input onChange={(e) => setAmount(e.target.value)} type="text" class="form-control border-primary" aria-label="Amount (to the nearest dollar)"/>
  <span class="input-group-text border-primary">.00</span>
</div>
            <input type="date" onChange={(e) => setDate(e.target.value)} class="form-control border border-primary w-75 " placeholder="Date of Transaction"/>
            <textarea onChange={(e) => setDescription(e.target.value)} type="text" class="form-control border border-primary w-75 mt-3" placeholder="Description"/>
            <div class="input-group mb-3 d-flex justify-content-center mt-3">
  <label class="input-group-text border-primary" for="inputGroupSelect01">Transaction Type</label>
  <select onChange={(e) => setTransaction_type(e.target.value)} class="form-select" className="border-primary w-25 text-center" id="inputGroupSelect01">
    <option value="Bank Transfer">Bank Transfer</option>
    <option value="Cash">Cash</option>
  </select>
</div>
          <button onClick={createTransaction} className="btn btn-outline-success">Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewEntry;
