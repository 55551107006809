import React, { useState, useEffect } from 'react';
import './PriceList.css';
import DashboardHeader from '../Admin/Dashboard/DashboardHeader';
import { useNavigate } from 'react-router-dom';

const PriceList = () => {
  const [inventory, setInventory] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const navigate = useNavigate();

  // Fetch data from the endpoint when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://apenterprise-client.onrender.com/items');
        const data = await response.json();
        setInventory(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Function to handle search
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };


  const redirectLowStock = () => {
    navigate('/pricelist/low-stock-items');
  }

  
  const handlePriceChange = async (index, field, value) => {
    const updatedInventory = [...inventory];
    updatedInventory[index][field] = value;
    setInventory(updatedInventory);

  
    const { _id, salePrice, purchasePrice } = updatedInventory[index];

    try {
      const response = await fetch(`https://apenterprise-client.onrender.com/items/${_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ salePrice, purchasePrice }),
      });

      if (!response.ok) {
        throw new Error('Failed to update item');
      }

      const result = await response.json();
      console.log(result.message); // Handle success message if needed
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredInventory = inventory.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const currentItems = filteredInventory.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredInventory.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <div>
      <DashboardHeader/>
    <div className="inventory-container">
      <h1>Inventory Search</h1>
      <div>
  <button className="order-button" onClick={redirectLowStock}>Order New Stock</button>
</div>
      <input
        className="search-bar"
        type="text"
        placeholder="Search items..."
        value={searchTerm}
        onChange={handleSearch}
      />

      <table className="inventory-table">
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Sale Price</th>
            <th>Purchase Price</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={item._id}>
              <td>{item.name}</td>
              <td>
                <input
                  className="price-input"
                  type="number"
                  value={item.salePrice}
                  onChange={(e) => handlePriceChange(index, 'salePrice', e.target.value)}
                />
              </td>
              <td>
                <input
                  className="price-input"
                  type="number"
                  value={item.purchasePrice}
                  onChange={(e) => handlePriceChange(index, 'purchasePrice', e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
    </div>
  );
};

export default PriceList;
