import React, { useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping,faScrewdriverWrench,faGears  } from "@fortawesome/free-solid-svg-icons";

const Services = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <div className="header">
      <Header/>
      </div>
      
      <div className="serviceBG">
        {/* <div className="bg-title-service">
        <h1>Services we offer</h1>
        </div> */}
        
      </div>
      <div className="service-offer">
        <div className="serviceoffer-heading">
          <hr className="hr-line-service" />
          <h2 className="title-service">Our Services</h2>
        </div>
        <div className="service-tiles-outer">
          <div className="service-tiles">
            <div className="service-tile">
              <FontAwesomeIcon
                size="2x"
                icon={faGears}
                beat
              />
              <h3 className="service-tile-heading">Electrical Maintenance</h3>
              <h5 className="service-tile-body">
                For factories and other industrial facilities that require
                reliable electrical systems, we provide comprehensive electrical
                maintenance services. Our experienced technicians perform
                routine maintenance checks, troubleshoot any issues, and make
                necessary repairs to keep your electrical systems running
                smoothly.
              </h5>
            </div>
            <div className="service-tile">
              <FontAwesomeIcon
                style={{ marginBottom: "0.6em" }}
                size="2x"
                icon={faScrewdriverWrench}
                beat
              />
              <h3 className="service-tile-heading">Electrical Contracting</h3>
              <h5 className="service-tile-body">
              We provide skilled technicians to WBSEDCL divisions, including Bhangore, Bidhannagar II. Our services include overhead line work, micro-tunneling for underground cables, and all tasks adhere to WBSEDCL standards. Our technicians are highly trained to ensure quality and reliability.
              </h5>
            </div>
            <div className="service-tile">
              <FontAwesomeIcon
                size="2x"
                icon={faScrewdriverWrench}
                beat
              />
              <h3 className="service-tile-heading">Electrical Wiring</h3>
              <h5 className="service-tile-body">
              Our electrical wiring services for industrial facilities are tailored to your needs and industry standards. Our expert electricians use top-quality materials and equipment to design and install a reliable and safe electrical system. We offer flexible installation schedules to minimize downtime and prioritize safety in everything we do.
              </h5>
            </div>
            <div className="service-tile">
              <FontAwesomeIcon
                style={{ marginBottom: "0.6em" }}
                size="2x"
                icon={faCartShopping}
                beat
              />
              <h3 className="service-tile-heading">Electrical Material Supply</h3>
              <h5 className="service-tile-body">
              We offer a wide range of high-quality industrial electrical materials to ensure that your operations run smoothly. From wires and cables to switches and circuit breakers, we source our materials from reputable manufacturers and prioritize quality and reliability. With our comprehensive selection, you can find the right materials to meet your specific needs.
              </h5>
            </div>
          </div>
          <div className="service-tile">
            <FontAwesomeIcon
              style={{ marginBottom: "0.6em" }}
              size="2x"
              icon={faCartShopping}
              beat
            />
            <h3 className="service-tile-heading">Hardware Supply</h3>
            <h5 className="service-tile-body">
            Our company offers a diverse range of industrial hardware materials to meet your specific needs. From gear oil and pipes to valves and fittings, we provide high-quality materials sourced from reputable manufacturers. Our team of experts can help you select the right materials for your industry and budget, ensuring that your operations run smoothly and efficiently.
            </h5>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;
