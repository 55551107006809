import React, { useEffect } from "react";
import Header from "../Header/Header";
import "./Home.css";
import Footer from "../Footer/Footer";

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="header">
      <Header />
      </div>
   
      <div className="bg-home">
        <div className="first-tile">
          <div className="home-content-first">
            <h1 className="title-head">Welcome to A.P Enterprise</h1>
            <h5 className="sub-title-head">
              Safe, Efficient, and Affordable Electrical Services for You
            </h5>
            <div className="sub-content-first">
              <hr className="hr-line" />
              <h5> COMMITTED TO SUCCESS</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="aboutUs">
        <div className="about-heading">
          <h1>About Us</h1>
        </div>
        <div className="about-tiles">
          <div className="about-tile">
            <div className="topic-head">
              <hr className="hr-line-tiles" />
              <h2>Our Background</h2>
            </div>
            <div className="background-content">
              <h5>
              A.P Enterprise is a Government Licensed Electrical Contracting company, established in 2010 by SK MD FIROZE, with a strong foundation in quality and reliability. Later, his son, SK MD IRFAN, joined the company, bringing fresh perspectives and expanding the business’s capabilities. Together, they have grown A.P Enterprise into a trusted name in the industry, known for dedication and excellence in every project.
              </h5>
              <h5>
              We have proudly worked on numerous projects over the years, spanning from factory maintenance to public sector collaborations. One of our most notable projects includes maintaining the Kolkata Leather Complex (CLC) Tanners Association, where we provide top-notch electrical services to ensure uninterrupted operations. Additionally, we have had the privilege of working with the West Bengal State Electricity Distribution Company Limited (WBSEDCL), delivering safe, reliable electrical solutions for public utilities.
              </h5>
              <h5>
                At our company, we prioritize quality workmanship and customer
                satisfaction. Our team of licensed and experienced electricians
                are equipped with the latest technology and tools to tackle any
                electrical project with ease. We believe in fostering long-term
                relationships with our clients and strive to exceed their
                expectations. We take pride in our work and are committed to
                providing exceptional service to our clients. If you are in need
                of electrical contracting services, don't hesitate to contact
                us. We look forward to working with you.
              </h5>
            </div>
          </div>


          <div className="about-tile">
            <div className="topic-head">
              <hr className="hr-line-tiles" />
              <h2>Our Vision</h2>
            </div>
            <div className="background-content">
              <h5>
              Our vision is to be the leading provider of electrical contracting services in our region, with a reputation for excellence and reliability. We aim to consistently deliver high-quality services that meet and exceed our clients' expectations. We strive to be a company that is known for its integrity, professionalism, and commitment to safety. Our goal is to build long-lasting relationships with our clients by providing personalized services and maintaining open lines of communication.

              </h5>
              <h5>
              We envision a future where our company is at the forefront of innovation in the electrical contracting industry, using the latest technology and techniques to improve efficiency and productivity. We are committed to providing ongoing training and professional development opportunities to our employees to ensure that they are equipped with the knowledge and skills needed to meet the evolving needs of our clients.
              </h5>
              <h5>
              Ultimately, our vision is to be a company that our clients can trust and rely on for all their electrical contracting needs, and a company that our employees are proud to be a part of.
              </h5>
            </div>
          </div>
        </div>
      </div>
     <Footer/>
    </div>
  );
};

export default Home;
