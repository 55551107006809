import React, { useState } from 'react';
import { collection, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase'; // Update the path according to your project structure
import './BillDetails.css';
import DashboardHeader from '../Admin/Dashboard/DashboardHeader';
import { useContext } from 'react';
import UserContext from '../../UserContext'; // Assuming you have a UserContext to get the current user

const BillDetails = () => {
  const { userDetails, isAdmin } = useContext(UserContext);
  const [invoices, setInvoices] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [user, setUser] = useState('');
  const [invoiceNo, setInvoiceNo] = useState('');
  const [consignee, setConsignee] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // You can adjust this value to show more or fewer items per page

  const invoiceRef = collection(db, "invoices");

  const handleSearch = async () => {
    let q = query(invoiceRef);
    let queries = [];
  
    if (!startDate && !endDate && !user && !invoiceNo && !consignee) {
      alert("Please enter the search parameters");
    } else {
      if (startDate && endDate) {
        q = query(q, where("date", ">=", startDate), where("date", "<=", endDate));
      }
  
      if (user) {
        q = query(q, where("user", "==", user));
      }
  
      if (invoiceNo) {
        // Create two separate queries: one for string and one for number
        const invoiceNoAsString = String(invoiceNo);
        const invoiceNoAsNumber = Number(invoiceNo);
  
        const stringQuery = query(q, where("invoiceNo", "==", invoiceNoAsString));
        const numberQuery = query(q, where("invoiceNo", "==", invoiceNoAsNumber));
  
        queries.push(getDocs(stringQuery), getDocs(numberQuery));
      }
  
      if (consignee) {
        const endConsignee = consignee + '\uf8ff'; // '\uf8ff' is the last possible Unicode character
        q = query(q, where("company", ">=", consignee), where("company", "<", endConsignee));
      }
  
      try {
        // If searching by invoice number, execute both queries and merge the results
        let invoicesData = [];
  
        if (queries.length > 0) {
          const [stringResults, numberResults] = await Promise.all(queries);
          const stringInvoices = stringResults.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          const numberInvoices = numberResults.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  
          // Merge the results, removing duplicates
          const mergedInvoices = [...stringInvoices, ...numberInvoices];
          invoicesData = mergedInvoices.filter((invoice, index, self) => 
            index === self.findIndex((t) => (
              t.id === invoice.id
            ))
          );
        } else {
          const querySnapshot = await getDocs(q);
          invoicesData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        }
  
        setInvoices(invoicesData);
        setCurrentPage(1); // Reset to the first page after search
      } catch (error) {
        console.error("Error fetching invoices: ", error);
      }
    }
  };
  

  // Function to handle reset
  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setUser('');
    setInvoiceNo('');
    setConsignee('');
    setInvoices([]);
  };

  // Function to handle deletion
  const handleDelete = async (invoiceId) => {
    const confirmation = window.confirm("Are you sure you want to delete this invoice? This action cannot be undone.");
  
    if (confirmation) {
      try {
        await deleteDoc(doc(db, "invoices", invoiceId));
        alert("Invoice deleted successfully");
        setInvoices(invoices.filter(invoice => invoice.id !== invoiceId)); // Remove deleted invoice from the state
      } catch (error) {
        console.error("Error deleting invoice: ", error);
        alert("Failed to delete the invoice");
      }
    } else {
      // If the user selects "No", do nothing
      console.log("Invoice deletion canceled");
    }
  };
  
  const canDeleteInvoice = (createdAt) => {
    const currentTime = new Date();
    const creationTime = createdAt.toDate(); // Convert Firestore timestamp to Date object
    const timeDifference = (currentTime - creationTime) / 1000 / 60 / 60; // Time difference in hours
  
    return timeDifference <= 2; 
  };

  // Calculate indices for pagination
  const indexOfLastInvoice = currentPage * itemsPerPage;
  const indexOfFirstInvoice = indexOfLastInvoice - itemsPerPage;
  const currentInvoices = invoices.slice(indexOfFirstInvoice, indexOfLastInvoice);

  // Pagination functions
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < Math.ceil(invoices.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <DashboardHeader />
      <div className="bill-details-container">
        <h2>Search Invoices</h2>

        <div className="search-filters">
          <div className="filter-item">
            <label>Start Date:</label>
            <input 
              type="date" 
              value={startDate} 
              onChange={(e) => setStartDate(e.target.value)} 
            />
          </div>

          <div className="filter-item">
            <label>End Date:</label>
            <input 
              type="date" 
              value={endDate} 
              onChange={(e) => setEndDate(e.target.value)} 
            />
          </div>

          <div className="filter-item">
            <label>User:</label>
            <input 
              type="text" 
              placeholder="Enter username" 
              value={user} 
              onChange={(e) => setUser(e.target.value)} 
            />
          </div>

          <div className="filter-item">
            <label>Invoice No:</label>
            <input 
              type="text" 
              placeholder="Enter invoice number" 
              value={invoiceNo} 
              onChange={(e) => setInvoiceNo(e.target.value)} 
            />
          </div>

          <div className="filter-item">
            <label>Consignee:</label>
            <input 
              type="text" 
              placeholder="Enter consignee" 
              value={consignee} 
              onChange={(e) => setConsignee(e.target.value)} 
            />
          </div>

          <div className="button-container">
            <button className="search-button" onClick={handleSearch}>
              Search
            </button>
            <button className="reset-button" onClick={handleReset}>
              Reset
            </button>
          </div>
        </div>

        <div className="invoice-results">
          <h3>Invoices</h3>
          <ul>
            {currentInvoices.length === 0 ? (
              <li>No invoices found</li>
            ) : (
              currentInvoices.map((invoice) => (
                <li key={invoice.id}>
                  <div className="invoice-details">
                    <strong>Invoice No:</strong> {invoice.invoiceNo} <br />
                    <strong>Date:</strong> {invoice.date} <br />
                    <strong>User:</strong> {invoice.user} <br />
                    <strong>Consignee:</strong> {invoice.company}
                  </div>
                  {isAdmin || (userDetails.user.email.split('@')[0] === invoice.user && canDeleteInvoice(invoice.createdAt)) ? (
                    <button
                      className="delete-button"
                      onClick={() => handleDelete(invoice.id)}
                    >
                      Delete
                    </button>
                  ) : null}
                </li>
              ))
            )}
          </ul>

          {/* Pagination Controls */}
          <div className="pagination-controls">
            <button onClick={prevPage} disabled={currentPage === 1}>
              Previous
            </button>
            <span>
              Page {currentPage} of {Math.ceil(invoices.length / itemsPerPage)}
            </span>
            <button onClick={nextPage} disabled={currentPage === Math.ceil(invoices.length / itemsPerPage)}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillDetails;


