import React, { useContext } from 'react';
import { Outlet, Navigate  } from 'react-router-dom';
import UserContext from './UserContext';

const PrivateRoute = () => {
    const { authenticated } = useContext(UserContext);
  
    return authenticated ? <Outlet /> : <Navigate to="/login" />;
  };

export default PrivateRoute;
